import { lazy } from "react";

const HomePage = lazy(() => import("../../pages/home/HomePage"));
const SubscriptionPage = lazy(() => import("../../pages/subscription/SubscriptionPage"));
const ProfilePage = lazy(() => import("../../pages/profile/ProfilePage"));
const NFTAssetsPage = lazy(() => import("../../pages/nft-assets/NFTAssetsPage"));
//const BoredApesGeneratorPage = lazy(() => import("../../pages/bored-apes-generator/BoredApesGeneratorPage"));
//const BoredApesGeneratorMint = lazy(() => import("../../pages/bored-apes-generator/BoredApesGeneratorMint"));
const StrategyRoomPage = lazy(() => import("../../pages/strategy-room/StrategyRoomPage"));

const GameHome = lazy(() => import("../../pages/game-home/GameHome"));
//const IndividualAssetInnerPage = lazy(() => import("../../pages/game-home/IndividualAssetInnerPage"));

export const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    secret: false,
    component: HomePage,
    menu: false,
    icon: "",
    tooltip: "Go Home",
  },
  {
    path: "/profile",
    name: "Profile",
    exact: true,
    secret: false,
    component: ProfilePage,
    menu: false,
    icon: "",
    tooltip: "Profile",
  },
  // {
  //   path: "/bored-apes-generator",
  //   name: "Bored Ape Generator",
  //   exact: true,
  //   secret: false,
  //   component: BoredApesGeneratorPage,
  //   menu: false,
  //   icon: "",
  //   tooltip: "Bored Apes",
  // },
  // {
  //   path: "/bored-apes-generator-mint",
  //   name: "Bored Ape Generator Mint",
  //   exact: true,
  //   secret: false,
  //   component: BoredApesGeneratorMint,
  //   menu: false,
  //   icon: "",
  //   tooltip: "Bored Apes",
  // },
  {
    path: "/assets",
    name: "Assets",
    exact: true,
    secret: false,
    component: NFTAssetsPage,
    menu: false,
    icon: "",
    tooltip: "NFT Assets",
  },
  {
    path: "/strategy",
    name: "Strategy",
    exact: true,
    secret: false,
    component: StrategyRoomPage,
    menu: false,
    icon: "",
    tooltip: "Play Now",
  },
  {
    path: "/game-home",
    name: "GameHome",
    exact: true,
    secret: false,
    component: GameHome,
    menu: false,
    icon: "",
    tooltip: "Play Now",
  },
  // {
  //   path: "/individual-asset-inner-page",
  //   name: "IndividualAssetInnerPage",
  //   exact: true,
  //   secret: false,
  //   component: IndividualAssetInnerPage,
  //   menu: false,
  //   icon: "",
  //   tooltip: "Play Now",
  //   class:"classYouWantApplied"
  // },
  {
    path: "/membership",
    name: "Membership",
    exact: true,
    secret: false,
    component: SubscriptionPage,
    menu: false,
    icon: "",
    tooltip: "Membership",
  },
];
export const dropDownRoutes = [
  {
    path: "/nft-assets",
    name: "My NFTs",
    exact: true,
    secret: false,
    component: HomePage,
    menu: true,
    icon: "",
  },
  {
    path: "/profile",
    name: "Edit Profile",
    exact: true,
    secret: false,
    component: HomePage,
    menu: true,
    icon: "iconName",
  },
];
