import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import { ethers } from 'ethers';
import app from '../../app';

export interface IWeb3Store {
  chainId: string;
  network: string;
  accounts: string[];
  balance: string;
  isInitialized: boolean;
  ethersProvider: ethers.providers.Provider;

}

export class Web3Store implements IWeb3Store {
  id = uuidv4();
  chainId = '';
  network = '';
  accounts = [];
  balance = "0";
  isInitialized = false;
  ethersProvider = this._getProvider();

  constructor() {
    makeAutoObservable(this);
  }

  _getProvider(): ethers.providers.Provider {
    if (window.ethereum !== undefined) {
      return new ethers.providers.Web3Provider(window.ethereum, 'any');
    } else {
      return ethers.providers.getDefaultProvider('rinkeby');
    }
  }

  @computed convertEthToWEI = (ethToConvert: string): ethers.BigNumber => {
    return ethers.utils.parseEther(ethToConvert);
  }
  @computed isConnected = (): boolean => {
    return this.accounts.length > 0;
  }
  @computed convertWEIToETH = (weiToConvert: string): string => {
    return ethers.utils.formatUnits(weiToConvert);
  }
  @action initializeWeb3 = async (): Promise<void> => {
    this.setNetworkDetails();
  }
  @action resetUser = (): void => {
    this.accounts = [];
  }
  @action setChainId = async (): Promise<void> => {
    try {
      this.chainId = await window.ethereum.request({
        method: 'eth_chainId',
      });
      this.network = getNetworkName(this.chainId);
    } catch (err) {
      console.error(err);
    }


  }
  @action setNetworkDetails = async (): Promise<void> => {
    await this.setAccounts();
    await this.setChainId();
    await this.setCurrentBalance();
  }
  @action setCurrentBalance = async (): Promise<void> => {
    const bal = await this.ethersProvider.getBalance(this.accounts[0]);
    const formattedBalance = ethers.utils.formatEther(bal).toString();
    this.balance = formattedBalance;
  }
  @action setAccounts = async (): Promise<string> => {
    const tempAccounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    this.accounts = tempAccounts;
    if (app.user.address && app.user.address != tempAccounts[0]) {
      // await app.fb.processLogoutRequest();
      window.location.reload();
    }
    app.user.address = tempAccounts[0];
    if (this.accounts.length > 0) {
      app.user.isConnected = true;
      app.user.loadUserByAddress(app.user.address);
    }
    return tempAccounts[0];
  }
  @computed isMetaMaskConnected = (): boolean => {
    return this.accounts && this.accounts.length > 0;
  }
  @action addChain = async (): Promise<void> => {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x64',
          rpcUrls: ['https://dai.poa.network'],
          chainName: 'Coston Testnet',
          nativeCurrency: { name: 'Spark', decimals: 18, symbol: 'FLR' },
          blockExplorerUrls: ['https://blockscout.com/poa/xdai'],
        },
      ],
    });
  }
  signPersonalMessage = async (signingAddress: string, messageToSign: string): Promise<string> => {
    const msg = `0x${Buffer.from(messageToSign, 'utf8').toString('hex')}`;
    const signature = await window.ethereum.request({
      method: 'personal_sign',
      params: [msg, signingAddress, 'SSN'],
    });
    return signature;
  }
}

export const web3Store = new Web3Store();



const getNetworkName = (chainId: string) => {
  switch (chainId) {
    case '1':
      return 'Ethereum - MainNet';
    case '3':
      return 'Ethereum - Ropsten';
    case '42':
      return 'Ethereum - Kovan';
    case '4':
      return 'Ethereum - Rinkeby';
    case '97':
      return 'BSC - Testnet';
    case '56':
      return 'BSC - MainNet';
    default:
      return '';
  }

};

