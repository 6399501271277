import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";

export interface IHorseGeneratorStore {
  imageSource: string;
}

export class HorseGeneratorStore implements IHorseGeneratorStore {
  id = uuidv4();
  imageSource = '';
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }



  @action async shuffleNFT(): Promise<void> {
    //this.isLoading = true;
    //  this.isLoading = false;
  }


  @computed getHorseImageUrl(): string {
    return this.imageSource;
  }

}