import { ethers } from "ethers";
import app from "../../../app";

const marketplaceAddress = '0x501C63e22c5A472785b316b3D18a033E6B589b5f';

// Buy item for sale 
export const buyItem = async (listingId: number, qtyToBuy: number, priceEach: number): Promise<void> => {
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const abi = ['function buyItem(uint256 _listingId, uint256 _qtyToBuy, uint256 _expectedPrice) external payable'];
  try {
    const marketplaceContract = new ethers.Contract(marketplaceAddress, abi, prov.getSigner());
    const totalPrice = priceEach * qtyToBuy;
    const formattedTotalPrice = app.web3.convertEthToWEI(totalPrice.toString());
    const formattedPriceEach = app.web3.convertEthToWEI(priceEach.toString());
    return await marketplaceContract.buyItem(listingId, qtyToBuy, formattedPriceEach, { value: formattedTotalPrice, gasLimit: 250000 });
  } catch (error) {
    console.log(error);
  }
};