import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, FormGroup, Input } from 'reactstrap';
import app from '../../../app';
//import '../../assets/strat-room-css/assets/css/bootstrap.css'
//import '../../assets/strat-room-css/assets/css/nucleo-icons.css'
//import '../../assets/strat-room-css/assets/css/nucleo-svg.css'
//import '../../assets/strat-room-css/assets/css/style.css'
import { ethers } from 'ethers';
import { approveAll1155 } from '../../../utils/nft-utils/approval-functions/approveAll';
import { getIsApprovedAll1155 } from '../../../utils/nft-utils/get-approved-functions/getApprovedAll';


const BurnPAModal = observer((): JSX.Element => {
  const [isApproved, setIsApproved] = useState(false);

  const isValid = (): boolean => {
    const ownsBA = app.baGenerator.planetBaTokenBalance >= 10;
    return ownsBA && isApproved;
  };
  const handleBurnClicked = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ["function burnBANFTsToGetWLNFT(uint256 _qty) external"];
      const contract = new ethers.Contract(app.baGenerator.wlBurnContract, abi, prov.getSigner());
      const tx = await contract.burnBANFTsToGetWLNFT(10, { gasLimit: 2000000 });
      const result = tx.wait();
      console.log(result);
      app.modal.closeModal();
    }
  };
  const handleApproveAllClicked = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const result = await approveAll1155(app.baGenerator.generatorNFTContractAddress, app.baGenerator.wlBurnContract, true, prov);
      console.log(result);
      app.modal.closeModal();
    }
  };
  const checkIfApproved = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const approved: boolean = await getIsApprovedAll1155(app.baGenerator.generatorNFTContractAddress, app.user.address, app.baGenerator.wlBurnContract, prov);
      console.log(approved);
      setIsApproved(approved);
    }
  };

  useEffect(() => {
    // setQty(app.baGenerator.wlTokenBalance);
    checkIfApproved();
  }), [];
  return (
    <Modal className="largeModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Burn 10 Planet of the BA for a Whitelist</h4>
      </div>
      <div className="modal-body">
        <p className="text-center">
          You are about to burn some <strong>Planet of the Bored Apes</strong> to get <strong>1 Whitelist NFT</strong>.
        </p>

        <div className="inputlines">


          <FormGroup>
            <label>Quantity to burn</label>

            <Input
              min="0"
              placeholder="Quantity"
              id="qte"
              defaultValue="10"
              type="number"
            />
            <div className="subinput">
              Currently you own <span>{app.baGenerator.planetBaTokenBalance}</span> Planet of the Bored Apes
            </div>

          </FormGroup>
        </div>

        {/* <div className="biddetails">
          <span className="left">You will pay</span>
          <span className="right">0 SGB</span>
        </div> */}
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {isValid() ? <Button onClick={() => handleBurnClicked()}
          color="default"
          className="btn-round"
          type="button"
        >
          Burn my NFT(s)
        </Button> : <Button onClick={() => handleApproveAllClicked()}
          color="default"
          className="btn-round"
          type="button"
        >
          Approve
        </Button>}
      </div>
    </Modal>
  );
});
export default BurnPAModal;
