
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import playButton from '../../../assets/img/icon/play_btn.png';
import aboutUsImage from '../../../assets/img/images/about_us_img.jpg';

const VideoSection = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="area-bg-one">
      <React.Fragment>
        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="w9LjDHyVCBw" onClose={() => setOpen(false)} />
      </React.Fragment>
      <div id="kingdom-awaits" className="scroll-target"></div>
      <section className="about-us-area pt-90 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-0 order-lg-2">
              <div className="about-img">
                <img src={aboutUsImage} alt="" />
                <a className="popup-video">
                  <img src={playButton} alt="" onClick={() => setOpen(true)} />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title title-style-one mb-45">
                <h2>The Kingdom <span>Awaits</span></h2>
              </div>
              <div className="about-content">
                <p>Kings of Strategy will be the most revolutionary NFT strategy game of our time. We’ve created the foundation to build a complete virtual sports economy. </p>
                <p>Never before have gamers been able to own and monetize their gaming assets in a way that combines passive income with gaming entertainment. We’ve done that and we can’t wait to tell you all about it!</p>
                <a href="/wp.pdf" className="btn wow fadeInUp" data-wow-delay="0.2s" download>White Paper</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default VideoSection;