import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, FormGroup, Input } from 'reactstrap';
import app from '../../../app';
import { ethers } from 'ethers';
import { approveAll1155 } from '../../../utils/nft-utils/approval-functions/approveAll';
import { getIsApprovedAll1155 } from '../../../utils/nft-utils/get-approved-functions/getApprovedAll';
//import '../../assets/strat-room-css/assets/css/bootstrap.css'
//import '../../assets/strat-room-css/assets/css/nucleo-icons.css'
//import '../../assets/strat-room-css/assets/css/nucleo-svg.css'
//import '../../assets/strat-room-css/assets/css/style.css'


const BurnWlModal = observer((): JSX.Element => {
  const [qty, setQty] = useState(1);
  const [isApproved, setIsApproved] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const handleBurnClicked = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ["function burnWLNFTs(uint256 _qty) external payable"];
      const contract = new ethers.Contract(app.baGenerator.wlBurnContract, abi, prov.getSigner());
      const cost = 300;
      const formattedValue = app.web3.convertEthToWEI(cost.toString());
      const tx = await contract.burnWLNFTs(qty, { value: formattedValue, gasLimit: 2000000 });
      const result = tx.wait();
      console.log(result);
      app.modal.closeModal();
    }
  };

  const handleQtyInputChanged = (value: string) => {
    if (isInit) {

    } else {
      setQty(parseInt(value));
      setIsInit(true);
    }
  };


  const handleApproveAllClicked = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const result = await approveAll1155(app.baGenerator.generatorNFTContractAddress, app.baGenerator.wlBurnContract, true, prov);
      console.log(result);
      app.modal.closeModal();
    }
  };
  const checkIfApproved = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const approved: boolean = await getIsApprovedAll1155(app.baGenerator.generatorNFTContractAddress, app.user.address, app.baGenerator.wlBurnContract, prov);
      console.log(approved);
      setIsApproved(approved);
    }
  };

  const isValid = (): boolean => {
    const hasBalance = app.wallet.sgbBalance > 300;
    const ownsWL = app.baGenerator.wlTokenBalance >= qty;
    return hasBalance && ownsWL && isApproved && qty > 0;
  };
  const showApproveButton = (): boolean => {
    const hasBalance = app.wallet.sgbBalance > 300;
    const ownsWL = app.baGenerator.wlTokenBalance >= qty;
    return hasBalance && ownsWL && !isApproved && qty > 0;
  };

  useEffect(() => {
    handleQtyInputChanged(app.baGenerator.wlTokenBalance.toString());
    checkIfApproved();
  }), [];

  return (
    <Modal className="largeModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Burn your Whitelist for a Generator NFT</h4>
      </div>
      <div className="modal-body">
        <p className="text-center">
          You are about to burn some <strong>Bored Apes XRPL utility fork on SGB</strong> (whitelist) to get the Generator NFT.
          This is irreversible and will cost you <span className="number-enlight">300 SGB</span>
        </p>

        <div className="inputlines">


          <FormGroup>
            <label>Quantity to burn</label>
            <Input
              min="1"
              placeholder="Quantity"
              id="qte"
              value={qty}
              type="text"
              onChange={(e) => setQty(parseInt(e.target.value))}
            />
            <div className="subinput">
              Currently you own <span>{app.baGenerator.wlTokenBalance}</span> BASGB whitelist.
            </div>

          </FormGroup>
        </div>

        <div className="biddetails">
          <span className="left">You will pay</span>
          <span className="right">300 SGB</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {isValid() ? <Button onClick={() => handleBurnClicked()}
          color="default"
          className="btn-round"
          type="button"
        >
          Burn my Whitelist(s)
        </Button> : <></>}
        {showApproveButton() ? <Button onClick={() => handleApproveAllClicked()}
          color="default"
          className="btn-round"
          type="button"
        >
          Approve
        </Button> : <></>}
      </div>
    </Modal>
  );
});
export default BurnWlModal;