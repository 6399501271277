import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';
//import '../../assets/strat-room-css/assets/css/bootstrap.css'
//import '../../assets/strat-room-css/assets/css/nucleo-icons.css'
//import '../../assets/strat-room-css/assets/css/nucleo-svg.css'
//import '../../assets/strat-room-css/assets/css/style.css'

import {Accordion} from 'react-bootstrap';


const InstructionsModal = observer((): JSX.Element => {
  return (
    <Modal className="largeModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Intructions / FAQ</h4>
      </div>
      <div className="modal-body">
      <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>How to mint a BASGB? (Prerequisite)</Accordion.Header>
    <Accordion.Body>
     To generate your unique Ape you need to own a BASGB Generator NFT (this NFT will be burned in the minting process) and Shuffle points ($GEN ticker) allowing you to shuffle traits before minting.

    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>How to get a BASGB Generator NFT?</Accordion.Header>
    <Accordion.Body>
     There are three ways to get a BASGB Generator NFT:<br/>
     <ul>
       <li>
       Get a <strong>Bored Apes XRPL utility fork on SGB NFT</strong> (whitelist) from the BestFTSO Quest (quest is over now) or buy it on BestFTSO website.
       </li>
       <li>Get a <strong>whitelist</strong> by burning 10 <strong>Planet of the Bored Apes NFT</strong>.</li>
       <li>Purchase a Generator NFT on this application or on BestFTSO website / tab Collaborations / BASGB Generator NFT.</li>
     </ul>

    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="2">
    <Accordion.Header>Where and how to buy shuffle packs?</Accordion.Header>
    <Accordion.Body>
    <ul>
       <li>If you want to get shuffle packs with $SGB go to https://bestftso.xyz/nfts <br/>
         on «NFTp claimable» tab search for the <strong>Bored Apes SGB - Shuffle Pack</strong> <br/>
         or under « Collaborations» tab for <strong>NFTP Generator Shuffle Pack V2</strong> .</li>
       <li>In order to get a shuffle pack (2<sup>nd</sup> edition) with NFTp, go on top left side of your screen and click on « Get a shuffle pack ». These ones are buyable with 20 NFTp. </li>
     </ul>
     NB: Please check that your wallet is properly connected to songbird network and to marketplaces, ensure you have enough SGB tokens or NFTp in your wallet.
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="3">
    <Accordion.Header>What to do with a whitelist NFT?</Accordion.Header>
    <Accordion.Body>
      You can burn this NFT to get a Generator. On the top left side of your screen click on « Burn NFTs », select « Bored Apes XRPL (Whitelist) » and « Burn it ».<br/>
      <strong>NB: please do not burn it on BESTFTSO otherwise you’ll lose it!</strong><br/><br/>
      <strong>Burning fees:</strong> 300 SGB with 2 validations to proceed:<br/>first time sets approval (with a single transaction fees) and second one to burn.
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="4">
    <Accordion.Header>After buying my shuffle packs, how to obtain my shuffle points ($GEN)?</Accordion.Header>
    <Accordion.Body>
    You’ve got to burn your shuffle pack on this platform.<br/>
    Click on top left side of your screen on « Burn NFTs », select the option that will pop up « BASGB – Shuffle Pack – 1st edition or 2nd edition » accordingly, click on the button « Burn it », precise the quantity to burn and proceed with 2 validations, first time sets approval (with a single transaction fees) and second time burns.
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="5">
    <Accordion.Header>How many shuffles in each pack? </Accordion.Header>
    <Accordion.Body>
    5 shuffles in each pack (exactly the same shuffles quantity for pack edition 1 and edition 2). So 2 packs equals 10 shuffles, 3 packs 15 shuffles and so on...<br/>
    You can buy as many shuffle packs as you desire to increase your chance to get your perfect Ape.</Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="6">
    <Accordion.Header>Are we able to choose our favorite shuffle then?</Accordion.Header>
    <Accordion.Body>
    There’s no going back to a previous ape you’ve liked during a shuffle if you passed over it. Meaning you pass and that ape is gone... ! So as soon as you find your « love » you must mint it to remove this traits combination and keep it yours.</Accordion.Body>
  </Accordion.Item>


</Accordion>


      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>
        {/* <Button
          color="default"
          className="btn-round"
          type="button"

        >
          Burn my Whitelist
        </Button> */}
      </div>
    </Modal>
  );
});
export default InstructionsModal;