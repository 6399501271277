

import { VarsStore } from "../@state/vars/varsStore";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { UserStore } from "../@state/user/userStore";
import { FirebaseStore } from "../@state/firebase/firebaseStore";
import { ModalStore } from "../@state/modal/modalStore";
import { Web3Store } from "../@state/web3/web3Store";
import { WLGeneratorStore } from "../@state/wl-generator/wlGeneratorStore";
import { HorseGeneratorStore } from "../@state/horse-generator/horseGeneratorStore";
import { BAGeneratorStore } from "../@state/ba-generator/baGeneratorStore";
import { WalletStore } from "../@state/wallet/walletStore";

class AppState {
  id = uuidv4();
  vars: VarsStore;
  web3: Web3Store;
  user: UserStore;
  fb: FirebaseStore;
  modal: ModalStore;
  wlGen: WLGeneratorStore;
  horseGen: HorseGeneratorStore;
  baGenerator: BAGeneratorStore;
  wallet: WalletStore;

  constructor() {
    makeAutoObservable(this);
    this.vars = new VarsStore();
    this.web3 = new Web3Store();
    this.fb = new FirebaseStore();
    this.user = new UserStore();
    this.modal = new ModalStore();
    this.wlGen = new WLGeneratorStore();
    this.horseGen = new HorseGeneratorStore();
    this.baGenerator = new BAGeneratorStore();
    this.wallet = new WalletStore();
  }
}

const app = new AppState();
export default app;
