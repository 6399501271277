import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';
//import '../../assets/strat-room-css/assets/css/bootstrap.css'
//import '../../assets/strat-room-css/assets/css/nucleo-icons.css'
//import '../../assets/strat-room-css/assets/css/nucleo-svg.css'
//import '../../assets/strat-room-css/assets/css/style.css'
//import BAXRPQuest from '../../../assets/img/nft/BoredApeXRP-Quest.jpg';
import ShufflePack1 from '../../../assets/img/nft/shuffle-pack-1.png';
import ShufflePack2 from '../../../assets/img/nft/shuffle-pack-2.png';
import Whitelist from '../../../assets/img/nft/utility-fork.jpg';

const handleBurnWlClicked = () => {
  app.modal.openModal("burn-wl");
};

const handleBurnSP1Clicked = () => {
  app.modal.openModal("burn-sp1");
};


const handleBurnSP2Clicked = () => {
  app.modal.openModal("burn-sp2");
};


// const handleBurnPAClicked = () => {
//   app.modal.openModal("burn-pa");
// };

const BurnNFTsModal = observer((): JSX.Element => {
  return (
    <Modal className="largeModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Burn NFTs</h4>
      </div>
      <div className="modal-body">
        <p className="text-center">
          Select the NFT(s) that you want to burn.
        </p>

        {/* <div className="row paddingCol text-left">
          <div className="col-sm-2 imageContainer">
            <img src={BAXRPQuest} width="100%" />
          </div>

          <div className="col-sm-7">
            <h5>Planet of the Bored Apes</h5>
            Burn <strong>10 copies</strong> to get <strong>1 Whitelist</strong>
          </div>

          <div className="col-sm-3 btnCol">
            <Button
              color="default"
              className="btn-round"
              type="button"
              onClick={() => handleBurnPAClicked()}
            >
              Burn it
            </Button>
          </div>

        </div> */}


        <div className="row paddingCol text-left">
          <div className="col-sm-2 imageContainer">
            <img src={Whitelist} width="100%" />
          </div>

          <div className="col-sm-7">
            <h5>Bored Apes XRPL (Whitelist)</h5>
            Burn <strong>1 copy</strong> to get <strong>1 Generator NFT</strong>
          </div>

          <div className="col-sm-3 btnCol">
            <Button
              color="default"
              className="btn-round"
              type="button"
              onClick={() => handleBurnWlClicked()}
            >
              Burn it
            </Button>
          </div>

        </div>

        <div className="row paddingCol text-left">
          <div className="col-sm-2 imageContainer">
            <img src={ShufflePack1} width="100%" />
          </div>

          <div className="col-sm-7">
            <h5>BASGB - Shuffle Pack - Ist Edition</h5>
            Burn <strong>1 copy</strong> to get <strong>5 Shuffle points</strong>
          </div>

          <div className="col-sm-3 btnCol">
            <Button
              color="default"
              className="btn-round"
              type="button"
              onClick={() => handleBurnSP1Clicked()}
            >
              Burn it
            </Button>
          </div>

        </div>

        <div className="row paddingCol text-left">
          <div className="col-sm-2 imageContainer">
            <img src={ShufflePack2} width="100%" />
          </div>

          <div className="col-sm-7">
            <h5>BASGB - Shuffle Pack - 2nd Edition</h5>
            Burn <strong>1 copy</strong> to get <strong>5 Shuffle points</strong>
          </div>

          <div className="col-sm-3 btnCol">
            <Button
              color="default"
              className="btn-round"
              type="button"
              onClick={() => handleBurnSP2Clicked()}
            >
              Burn it
            </Button>
          </div>

        </div>

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {/* <Button
          color="default"
          className="btn-round"
          type="button"

        >
          Burn my Whitelist
        </Button> */}
      </div>
    </Modal>
  );
});
export default BurnNFTsModal;