import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import app from '../../app';
import firebase from "firebase/app";
import 'firebase/functions'; // make sure you add this for firestore

export interface IWLGeneratorStore {
  imageSource: string;
  isEligible: boolean;
  hasClaimed: boolean;
  email: string;
  isWhitelisted: boolean;
  step: number;
  registeredTime: number;
}

export class WLGeneratorStore implements IWLGeneratorStore {
  id = uuidv4();
  imageSource = '';
  isEligible = false;
  hasClaimed = false;
  isWhitelisted = false;
  registeredTime = 0;
  lastAcceptableTime = 0;
  email = '';
  step = 1;
  l1SpotsRemaining = 0;
  isLoading = false;


  constructor() {
    makeAutoObservable(this);
  }

  @action initializeVars(): void {
    console.log('');
  }

  @action async shuffleNFT(): Promise<void> {
    //  this.isLoading = true;
    // const src = await getRandomWLImage();
    // this.setImageSource(src);
    //  this.isLoading = false;
  }

  @action setImageSource(newSource: string): void {
    this.imageSource = newSource;
  }

  @action setEmailAddress(email: string): void {
    this.email = email;
  }
  @action setRegisteredTime(newTime: number): void {
    this.registeredTime = newTime;
  }

  @action resetSteps(): void {
    this.step = 1;
  }
  @action async goToNextStep(): Promise<void> {
    if (this.step === 1) {
      await this.checkEmailAgainstWL();
    } else if (this.step === 2) {
      await this.confirmWLAddress();
      app.modal.closeModal();
    }
    this.step = this.step + 1;
  }

  @action backToPreviousStep(): void {
    this.step = this.step - 1;
  }

  @action confirmWLAddress = async (): Promise<string> => {
    const messageToSign = "The Kingdom Awaits... (WL)";
    const messageSignature = await app.web3.signPersonalMessage(app.user.address, messageToSign);
    try {
      const getAuthToken = firebase
        .functions()
        .httpsCallable("validateWhitelistSignature");
      const result = await getAuthToken({
        address: app.user.address,
        emailAddress: this.email,
        signature: messageSignature,
      });
      const token = result.data.token;
      // Read result of the Cloud Function.
      const loginResult = await app.fb.db?.app
        .auth()
        .signInWithCustomToken(token);
      const uid = loginResult?.user?.uid;
      const isNewUser = loginResult?.additionalUserInfo?.isNewUser;
      if (isNewUser && uid) {
        this.registerNewUser(uid.toLowerCase());
      }
      console.log("uid ", uid);
      return uid ? uid : "";

    } catch (err) {
      console.error(err);
      return "Error";
    }
  }

  @action registerNewUser = async (uid: string): Promise<void> => {
    console.log("GH", uid);
    try {
      const userDocument = app.fb.db?.collection("users").doc(uid);
      userDocument?.set({
        address: uid,
        userName: uid,
        avatar: "",
        registered: Date.now(),
      });
    } catch (err) {
      console.log("LOGOUT_ERROR", err);
    }
  };
  @action async checkEmailAgainstWL(): Promise<void> {
    try {
      const checkEmailOnList = firebase.functions().httpsCallable("checkEmailOnList");
      const result = await checkEmailOnList({
        emailAddress: this.email,
      });
      console.log("result ", result.data);
      if (result.data.data === 'we have you on the list!') {

      } else if (result.data.data === 'has been added to the mailing list!') {

      } else {
        const data = result.data.data;
        console.log("data ", data);
        this.setEmailAddress(data.emailAddress);
        this.setRegisteredTime(parseInt(data.timeStamp));
      }
      return result.data;
    } catch (error) {
      console.log('error', error);

    }
  }
  @action async _setLastAcceptedTime(newTime: number): Promise<void> {
    this.lastAcceptableTime = newTime;
  }
  @action async _setSpotsRemaining(spotsRemaining: number): Promise<void> {
    this.l1SpotsRemaining = spotsRemaining;
  }

  @action async setLastAcceptedUserTime(): Promise<void> {
    if (app.fb.db) {
      const resultDoc = await app.fb.db.collection('listManager').doc('manager').get();
      const data = resultDoc.data();
      if (data) {
        console.log('GF4 ', data.lastUser);
        this._setLastAcceptedTime(parseInt(data.acceptedBefore));
        this._setSpotsRemaining(900 - data.lastUser);
      }
    }
  }
  @action async loadWLStatus(): Promise<void> {
    await this.checkIfAddressIsWhitelisted();
    // if (app.fb.db && app.user.address) {
    //   const wlData = await app.fb.db.collection('whiteList').doc('trackerl1').collection('confirmed').doc(app.user.address.toLowerCase()).get();
    //  console.log('wlData ', wlData)
    //   if (wlData.exists) {
    //  this.isWhitelisted = true;
    //  const docData = wlData.data();
    //  this.email = docData?.email;
    //  this.setLastAcceptedUserTime();
    //   }
    //   }
  }
  @action async checkIfAddressIsWhitelisted(): Promise<void> {
    //  console.log('GHss')
    // const address = app.user.address;
    const userData = await app.fb.getUserWLDocument() as Record<string, unknown>;
    console.log('userData ', userData);
    const userAddress = userData.address as string;
    if (userData && userAddress) {
      this.isWhitelisted = userAddress.length > 0;
    }
  }

  @computed getWLImageUrl(): string {
    return this.imageSource;
  }
}