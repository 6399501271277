import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, FormGroup, Input, } from 'reactstrap';
import app from '../../../app';
import { ethers } from 'ethers';


const MintShufflePacksModal = observer((): JSX.Element => {
  const [qty, setQty] = useState(1);
  const [totalCost, setTotalCost] = useState(20);
  const [allowance, setAllowance] = useState(0);

  const handleApproveClicked = async (): Promise<void> => {
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ['function approve(address spender, uint256 amount) external returns (bool)'];
      const nftpContract = new ethers.Contract(app.wallet.nftpAddress, abi, prov.getSigner());
      const allowance = ethers.utils.parseEther(totalCost.toString());
      return await nftpContract.approve(app.baGenerator.nftpShufflePacksAddress, allowance);
    } catch (error) {
      console.log(error);
    }
  };
  const handleMintClicked = async (): Promise<void> => {
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ['function mint(uint256 _tokenId, uint256 _qty) external'];
      const shufflePackContract = new ethers.Contract(app.baGenerator.nftpShufflePacksAddress, abi, prov.getSigner());
      const result = await shufflePackContract.mint(1, qty, { gasLimit: 500000 });
      console.log(result, 'result');
      const tx = await result.wait();
      console.log(tx, 'mint tx');

    } catch (error) {
      console.log(error);
    }
  };

  const checkAllowance = async (): Promise<void> => {
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ['function allowance(address owner, address spender) external view returns(uint256)'];
      const nftpContract = new ethers.Contract(app.wallet.nftpAddress, abi, prov);
      const result = await nftpContract.allowance(app.user.address, app.baGenerator.nftpShufflePacksAddress);
      const allowance = app.web3.convertWEIToETH(result);
      console.log(allowance);
      setAllowance(parseInt(allowance));

    } catch (error) {
      console.log(error);
    }
  };

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQty(parseInt(e.target.value));
    setTotalCost(parseInt(e.target.value) * 20);
  };

  const isValid = (): boolean => {
    const qtyIsValid = qty > 0;
    const hasBalance = app.wallet.nftpBalance >= totalCost;
    const hasAllowance = allowance >= totalCost;
    return qtyIsValid && hasBalance && hasAllowance;
  };

  useEffect(() => {
    checkAllowance();
  }, [app.user.address]);

  return (
    <Modal className="largeModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Burn 20 NFTps for 1 Shuffle Pack NFT</h4>
      </div>
      <div className="modal-body">
        <p className="text-center">
          You are about to  <strong>burn {totalCost} NFTPs</strong> to get {qty} Shuffle Pack NFT(s).
        </p>

        <div className="inputlines">


          <FormGroup>
            <label>Quantity to Mint</label>

            <Input
              min="1"
              placeholder="Quantity"
              id="qte"
              value={qty}
              onChange={(e) => handleQtyChange(e)}
              type="number"
            />
            <div className="subinput">
              Currently you have <span>{app.wallet.nftpBalance}</span> NFTps and <span>{app.baGenerator.shufflePackV2Balance}</span> Shuffle Packs.
            </div>

          </FormGroup>
        </div>

        <div className="biddetails">
          <span className="left">You will burn</span>
          <span className="right">{totalCost} NFTPs</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {isValid() ? <Button
          color="default"
          className="btn-round"
          type="button"
          onClick={handleMintClicked}

        >
          Mint my Shuffle Pack(s)
        </Button> : <Button
          color="default"
          className="btn-round"
          type="button"
          onClick={handleApproveClicked}
        >
          Approve NFTP
        </Button>
        }
      </div>
    </Modal>
  );
});
export default MintShufflePacksModal;