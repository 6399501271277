import { observer } from 'mobx-react';
import React from 'react';
import app from '../../../../app';
import moment from 'moment';

export const Step2WL = observer((): JSX.Element => {

  const getUsersRegistrationDate = () => {
    const dateToUse = app.wlGen.registeredTime ? app.wlGen.registeredTime : Date.now();
    return moment(dateToUse).calendar();
  };

  const getLastAcceptedUserTime = () => {
    return moment(app.wlGen.lastAcceptableTime).calendar();
  };

  const eligibleDiv = <div>
    <p>
      <strong>Congratulations,</strong> you are eligible! We are accepting users who added their email address on or before <strong>{getLastAcceptedUserTime()}!</strong>
    </p>
    <p>
      You will be asked to sign a message to link your email address to your SGB address.
    </p>
  </div>;
  const notEligibleDiv = <p>
    Currently we are accepting users who added their email address on or before <strong>{getLastAcceptedUserTime()}</strong> please check back soon!
  </p>;

  const getEligibilityDiv = (): JSX.Element => {
    return app.wlGen.registeredTime < app.wlGen.lastAcceptableTime ? eligibleDiv : notEligibleDiv;
  };





  return (
    <div>
      <div className="modal-body">      <p>
        Your email address is on our list with the registration date shown below.
      </p>
        <div>
          <div className="biddetails">
            <span className="left">Email</span>
            <span className="right"> {app.wlGen.email} </span>
          </div>
          <div className="biddetails">
            <span className="left">Registration Date</span>
            <span className="right"> {getUsersRegistrationDate()} </span>
          </div>
          <br></br>
        </div>
        {getEligibilityDiv()}

      </div>
    </div>
  );
});