import React from 'react';
import { Button, Modal } from 'reactstrap';
import app from '../../../app';
import { Step1WL } from './sub-modals/Step1';
import { Step2WL } from './sub-modals/Step2';
import { Step3WL } from './sub-modals/Step3';
import { observer } from 'mobx-react';
const WhiteListModal = observer((): JSX.Element => {


  const getStepNumber = () => {
    return app.wlGen.step + 1;
  };

  const getStep = (): JSX.Element => {
    switch (app.wlGen.step) {
      case 1:
        return <Step1WL></Step1WL>;
      case 2:
        return <Step2WL></Step2WL>;
      case 3:
        return <Step3WL></Step3WL>;
      default:
        return <Step1WL></Step1WL>;
    }
  };

  const determineIfUserIsEligible = (): boolean => {
    console.log('step ', app.wlGen.step);
    console.log('registeredTime ', app.wlGen.registeredTime);
    console.log('lastAcceptableTime ', app.wlGen.lastAcceptableTime);
    return app.wlGen.registeredTime < app.wlGen.lastAcceptableTime || app.wlGen.step === 1;
  };


  return (

    <Modal className="smallModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">The Kingdom Awaits</h4>
      </div>
      <div className="modal-body">
        <p>
          Kings of Strategy <strong>Whitelist</strong> process!
        </p>

        {getStep()}

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {determineIfUserIsEligible() ? <Button onClick={() => app.wlGen.goToNextStep()}
          color="default"
          className="btn-round"
          type="button"
        >
          Step {getStepNumber()}
        </Button> : <></>}

      </div>
    </Modal>
  );
});
export default WhiteListModal;