import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row } from 'reactstrap';
import app from '../../../app';
import moment from 'moment';

const SubscribeModal = (): JSX.Element => {
  const [numberOfYears, setNumberOfYears] = useState(1);


  const calculateExpDate = (): string => {
    return moment().add((365 * numberOfYears), 'days').calendar();
  };

  return (

    <Modal className="smallModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">The Kingdom Awaits</h4>
      </div>
      <div className="modal-body">
        <p>
          You are about to purchase a <strong>Level 1</strong> subscription  to the most exclusive club in NFT Gaming!
        </p>

        <div className="inputlines">
          <FormGroup>
            <label>Price</label>
            <Row>
              <Col sm="10">
                <Input
                  min="1"
                  placeholder="Price"
                  id="price"
                  type="number"
                  disabled
                />
              </Col>
              <Col sm="2">
                <h3 className="asset gradient-text ticker">SGB</h3>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <label>Number of Years</label>
            <Row>
              <Col sm="12">
                <Input
                  min="1"
                  placeholder="Quantity"
                  id="qte"
                  defaultValue="1"
                  type="number"
                  value={numberOfYears}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumberOfYears(parseInt(e.target.value))}
                />
                <div className="subinput">
                  <span>{calculateExpDate()}</span> expiration
                </div>
              </Col>
            </Row>
          </FormGroup>
        </div>
        {/*  <div className="biddetails">
          <span className="left">Your bidding balance (escrow)</span>
          <span className="right"> {Number(app.web3.balance).toFixed(3)} SGB </span>
        </div> */}
        <div className="biddetails">
          <span className="left">Your balance (wallet)</span>
          <span className="right"> {Number(app.web3.balance).toFixed(3)} SGB </span>
        </div>
        {/*     <div className="biddetails">
          <span className="left">Service fee - 2.5%</span>
          <span className="right">0 SGB</span>
        </div> */}
        <div className="biddetails">
          <span className="left">You will pay</span>
          <span className="right">0 SGB</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Coming Soon!
        </Button>
        {/* <Button
          color="default"
          className="btn-round"
          type="button"

        >
          Proceed to Payment
        </Button> */}
      </div>
    </Modal>
  );
};
export default SubscribeModal;