import React from 'react';
import { observer } from 'mobx-react';
import BaseModal from './base-modal/BaseModal';
import PrivacyPolicyModal from './privacy-policy/PrivacyPolicyModal';
import app from '../../app';
import BuyModal from './buy-modal/BuyModal';
import WhitelistModal from './white-list-modal/WhiteListModal';
import SubscribeModal from './subscribe-modal/SubscribeModal';
import BurnWlModal from './burn-wl-modal/BurnWlModal';
import BurnSP1Modal from './burn-sp1-modal/BurnSP1Modal';
import BurnSP2Modal from './burn-sp2-modal/BurnSP2Modal';
import BurnPAModal from './burn-pa-modal/BurnPAModal';
import BurnNFTsModal from './burn-nfts-modal/BurnNFTsModal';
import InstructionsModal from './instructions-modal/InstructionsModal';
import MintShufflePacksModal from './mint-nftp-shuffle-pack/MintShufflePacksModal';

export const ModalShared = observer((): JSX.Element => {
  switch (app.modal.modalToShow) {
    case 'terms':
      return <BaseModal />;
    case 'privacy':
      return <PrivacyPolicyModal />;
    case 'subscribe':
      return <SubscribeModal />;
    case 'whitelist':
      return <WhitelistModal />;
    case 'burn-wl':
        return <BurnWlModal />;
    case 'burn-sp1':
        return <BurnSP1Modal />;
    case 'burn-sp2':
          return <BurnSP2Modal />;
    case 'burn-pa':
      return <BurnPAModal />;
    case 'burn-nfts':
      return <BurnNFTsModal />;
    case 'mint-shuffle-packs':
      return <MintShufflePacksModal />;
    case 'buy':
      return <BuyModal />;
    case 'instructions':
      return <InstructionsModal />;
    default:
      return <BaseModal />;
  }
});

