import { ethers } from "ethers";
import { GET_IS_APPROVED_ALL_FUNCTION_ABI_1155 } from "../abis/abi-1155/ABI_1155";
import { GET_IS_APPROVED_ALL_FUNCTION_ABI_721 } from "../abis/abi-721/ABI_721";


export const getIsApprovedAll721 = async (contractAddress: string, tokenOwner: string, operator: string, provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider | ethers.providers.BaseProvider): Promise<boolean> => {
  try {
    const tokenContract = new ethers.Contract(contractAddress, GET_IS_APPROVED_ALL_FUNCTION_ABI_721, provider);
    return await tokenContract.isApprovedForAll(tokenOwner, operator);
  } catch (error) {
    console.log('error ', error);
    return false;
  }
};

export const getIsApprovedAll1155 = async (contractAddress: string, tokenOwner: string, operator: string, provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider | ethers.providers.BaseProvider): Promise<boolean> => {
  try {
    const tokenContract = new ethers.Contract(contractAddress, GET_IS_APPROVED_ALL_FUNCTION_ABI_1155, provider);
    return await tokenContract.isApprovedForAll(tokenOwner, operator);
  } catch (error) {
    console.log('error ', error);
    return false;
  }
};
