import { BigNumber, ethers } from "ethers";
import { BALANCE_OF_ABI_1155, BALANCE_OF_BATCH_ABI_1155 } from "../abis/abi-1155/ABI_1155";



export const balanceOf1155 = async (contractAddress: string, ownerAddress: string, tokenId: number, provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider | ethers.providers.Provider): Promise<BigNumber> => {
  try {
    const tokenContract = new ethers.Contract(contractAddress, BALANCE_OF_ABI_1155, provider);
    return await tokenContract.balanceOf(ownerAddress, tokenId);
  } catch (error) {
    console.log('error ', error);
    return BigNumber.from(0);
  }
};


export const balanceOf1155Batch = async (contractAddress: string, ownerAddresses: string[], tokenIds: number[], provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider | ethers.providers.Provider): Promise<BigNumber> => {
  try {
    const tokenContract = new ethers.Contract(contractAddress, BALANCE_OF_BATCH_ABI_1155, provider);
    return await tokenContract.balanceOfBatch(ownerAddresses, tokenIds);
  } catch (error) {
    console.log('error ', error);
    return BigNumber.from(0);
  }
};

