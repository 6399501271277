import React from 'react';
import WOW from "wow.js";
import Slider from "react-slick";
class MainCarousel extends React.Component {
  //const [animating, setAnimating] = useState(false);
  settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1
  };
  componentDidMount(): void {
    const wow = new WOW();
    wow.init();
  }



  render(): JSX.Element {
    return (
      <section className="slider-area bright">
        <div className="slider-active">
          <Slider {...this.settings}>
            <div>
              <div className="single-slider slider-bg slider-2">
                <div className="container-fluid container-full-padding">
                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <div className="slider-content text-center">
                        <h6 className="wow fadeInDown" data-wow-delay=".2s">The First True</h6>

                        <div className="clip"><h2 className="tlt fix" data-in-effect="fadeInLeft">blockchain <span>strategy game</span></h2></div>

                        <p className="wow fadeInUp" data-wow-delay="1s">Where you can <strong>own</strong> a Virtual Sports Franchise.
                          <br />Build up your assets and Monetize your Empire.
                        </p>
                        <a href="#kingdom-awaits" className="btn wow fadeInUp" data-wow-delay="1.2s">The Kingdom Awaits</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="single-slider slider-bg slider-1">
                <div className="container-fluid container-full-padding">
                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <div className="slider-content text-center">
                        <h6 className="wow fadeInDown" data-wow-delay=".2s">The First True</h6>
                        <div className="clip"><h2 className="tlt fix" data-in-effect="fadeInLeft">blockchain <span>strategy game</span></h2></div>
                        <p className="wow fadeInUp" data-wow-delay="1s">Where you can <strong>own</strong> a Virtual Sports Franchise.
                          <br />Build up your assets and Monetize your Empire.
                        </p>
                        <a href="#kingdom-awaits" className="btn wow fadeInUp" data-wow-delay="1.2s">The Kingdom Awaits</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div>
              <div className="single-slider slider-bg slider-3">
                <div className="container-fluid container-full-padding">
                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <div className="slider-content text-center">
                        <h6 className="wow fadeInDown" data-wow-delay=".2s">The First True</h6>
                        <div className="clip"><h2 className="tlt fix" data-in-effect="fadeInLeft">blockchain <span>strategy game</span></h2></div>
                        <p className="wow fadeInUp" data-wow-delay="1s">Where you can <strong>own</strong> a Virtual Sports Franchise.
                          <br />Build up your assets and Monetize your Empire.
                        </p>
                        <a href="#kingdom-awaits" className="btn wow fadeInUp" data-wow-delay="1.2s">The Kingdom Awaits</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
export default MainCarousel;



