import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, FormGroup, Input } from 'reactstrap';
import app from '../../../app';
import { ethers } from 'ethers';
import { buyItem } from './buyItem';
//import '../../assets/strat-room-css/assets/css/bootstrap.css'
//import '../../assets/strat-room-css/assets/css/nucleo-icons.css'
//import '../../assets/strat-room-css/assets/css/nucleo-svg.css'
//import '../../assets/strat-room-css/assets/css/style.css'


const BuyModal = observer((): JSX.Element => {
  const abi = ['function getTotalForSaleBySeller(address _sellerAddress, uint256 _marketplaceId) public view returns(uint256)'];
  const [qtyForSale, setQtyForSale] = useState(0);
  const [qtyToBuy, setQtyToBuy] = useState(1);
  const [cost, setCost] = useState(3000);

  const getQtyForSale = async () => {
    const prov = app.web3.ethersProvider;
    const marketplaceAddress = '0x501c63e22c5a472785b316b3d18a033e6b589b5f';
    const sellerAddress = '0x4375daBA68D97573efaf2822B98cfaF582C23bAA';
    const contract = new ethers.Contract(marketplaceAddress, abi, prov);
    const qty = await contract.getTotalForSaleBySeller(sellerAddress, 46);
    setQtyForSale(qty.toNumber());
  };

  const updateCost = (valuePassed: string) => {
    const newQty = parseInt(valuePassed);
    setQtyToBuy(newQty);
    const cost = newQty * 3000;
    setCost(cost);
  };

  const buyIsValid = (): boolean => {
    const qtyIsValid = qtyToBuy > 0 && qtyToBuy <= qtyForSale;
    const priceIsValid = cost <= app.wallet.sgbBalance;
    return qtyIsValid && priceIsValid;
  };

  const handleBuyClicked = async () => {
    await buyItem(39616, qtyToBuy, 3000);
    app.modal.closeModal();
  };

  useEffect(() => {
    getQtyForSale();
  }), [app.user.address];

  return (
    <Modal className="smallModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Purchase a Bored Apes SGB Generator NFT</h4>
      </div>
      <div className="modal-body">
        <p className="text-center">
          You are about to purchase <strong>BASGB Generator NFT</strong>. <br />
          <span className="number-enlight">{qtyForSale}</span> are still available.
        </p>

        <div className="inputlines">


          <FormGroup>
            <label>Quantity</label>

            <Input
              min="0"
              placeholder="Quantity"
              id="qte"
              value={qtyToBuy}
              type="number"
              onChange={(e) => updateCost(e.target.value)}
            />
            <div className="subinput">
              Currently you own <span>{app.baGenerator.generatorTokenBalance}</span> BASGB Generator.
            </div>

          </FormGroup>
        </div>

        <div className="biddetails">
          <span className="left">You will pay</span>
          <span className="right">{cost} SGB</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {buyIsValid() ? <Button onClick={() => handleBuyClicked()}
          color="default"
          className="btn-round"
          type="button"
        >
          Proceed to Payment
        </Button> : <></>}
      </div>
    </Modal>
  );
});
export default BuyModal;