import React, { useState } from 'react';
import firebase from "firebase/app";
import 'firebase/functions'; // make sure you add this for firestore
import brainFooter from '../../../assets/img/images/brain-footer.png';
import app from '../../../app';

export const Footer = (): JSX.Element => {
  const [emailAdded, setEmailAdded] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resultText, setResultText] = useState('Has been added');
  // Set the Modal

  const handleButtonClicked = async (e): Promise<void> => {
    e.preventDefault();
    if (emailValid) {
      setEmailAdded(true);
      const result = await saveEmailToMailingList();
      setResultText(result);
    }
  };

  const handleChange = (newEmail: string): void => {
    //   console.log('RM ', newEmail);
    setEmailAddress(newEmail);
    setEmailValid(isValidEmailAddress(newEmail));
  };

  function isValidEmailAddress(addressToCheck: string) {
    return !!addressToCheck.match(/.+@.+/);
  }

  const saveEmailToMailingList = async (): Promise<string> => {
    const addToMailingList = firebase.functions().httpsCallable("addToMailingList");
    const result = await addToMailingList({
      emailAddress: emailAddress,
    });
    return result.data;
  };

  const emailInputForm: JSX.Element = <form onSubmit={handleButtonClicked}>
    <div className="newsletter-form-grp">
      <i className="far fa-envelope"></i>
      <input type="email" placeholder="Enter your email..." value={emailAddress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)} />
    </div>
    <button type="submit">SUBSCRIBE <i className="fas fa-paper-plane"></i></button>
  </form>;

  const emailFormResult: JSX.Element =
    <div className="newsletter-form-grp">
      You&#39;re on the list! The Kingdom Awaits...
    </div>;

  const getInputOrResult = (): JSX.Element => {
    return emailAdded ? emailFormResult : emailInputForm;
  };


  return (
    <>

      <footer className="footer">
        <div className="footer-top footer-bg" id="footer">
          <div className="newsletter-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="newsletter-wrap">
                    <div className="section-title newsletter-title">
                      <h2>Our <span>Newsletter</span></h2>
                    </div>
                    <div className="newsletter-form">
                      {getInputOrResult()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="#">
                      <img src={brainFooter} width="250px" alt="" />
                    </a>
                  </div>

                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Kings</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a href="/wp.pdf" download>White Paper</a></li>
                      <li><a href="#kingdom-awaits">Overview</a></li>
                      {/* <li><a href="/bored-apes-generator" target="_blank" rel="noreferrer">BASGB Generator</a></li> */}
                      {/*                       <li><a href="/">Top Level Assets</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Need Help?</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      {/*       <li onClick={() => app.modal.openModal('terms')}><a>Terms and Conditions</a></li> */}
                      <li onClick={() => app.modal.openModal('privacy')}><a href="#">Privacy Policy</a></li>
                      {/*  <li><a href="#">Franchise</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li><a href="https://twitter.com/kings_strategy" target='blank'><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://discord.com/invite/dvY5BTEWJt" target='blank'><i className="fab fa-discord"></i></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2021 <a href="/strategy">Kings of Strategy</a> All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-none d-md-block">
                <div className="payment-method-img text-right">
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
    </>
  );
};
