import { observer } from 'mobx-react';
import React from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import app from '../../../../app';

export const Step1WL = observer((): JSX.Element => {
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    app.wlGen.setEmailAddress(e.target.value);
  };

  return (

    <div>
      <p>
        Step 1 enter the email address<strong> THAT YOU REGISTERED WITH </strong> in the box below. If you have not registered please enter your email address below to be added to the list.
      </p>
      <div className="inputlines">
        <FormGroup>
          <label>Email Address Registered From</label>
          <Row>
            <Col sm="12">
              <Input
                placeholder="Address"
                id="qte"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(e)}
                value={app.wlGen.email}
                type="text"
              />
              <div className="subinput">
                <span>Step 1</span> Confirm Registration
              </div>
            </Col>
          </Row>
        </FormGroup>
      </div>

    </div>
  );
});
