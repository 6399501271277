import React from 'react';
import MainCarousel from '../../home/main-carousel/MainCarousel';
import VideoSection from '../../home/video-section/VideoSection';
import EconomySection from '../../home/economy/EconomySection';

const Main = (): JSX.Element => {
  return (
    <main>
      <MainCarousel />
      <VideoSection />
      <EconomySection />
      {/* <Collectibles /> */}
    </main>
  );
};
export default Main;