import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';

const BaseModal = observer((): JSX.Element => {

  return (
    <Modal className="smallModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">
        <button className="close" onClick={() => app.modal.closeModal()}>
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h4 className="title title-up">***Title***</h4>
      </div>
      <div className="modal-body">
        <p>Text</p>
        <div className="inputlines">

        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        <Button
          color="default"
          type="button"
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
});
export default BaseModal;
