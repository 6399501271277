import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";

export interface IVarsStore {
  baseURI: string;
  rinkebyConAdd: string;
}

export class VarsStore implements IVarsStore {
  id = uuidv4();
  baseURI = 'https://ipfs.io/ipfs/';
  menuIsOpen = false;
  rinkebyConAdd = "0xB07A7C5Ca5b5AC05202546632A0f84aC46430903";
  rinkebyTestDollar = "0xbb36Db7e6f0650846FaADe9326eA800271ef0F40";
  shuffleCreditMinterAddress = "0x089f89e2c1Bb9c566d0289A6Db0b0B904d6ba80F";

  constructor() {
    makeAutoObservable(this);
  }
  @action toggleMenu(): void {
    console.log('Toggle');
    this.menuIsOpen = !this.menuIsOpen;
    console.log('this.menuIsOpen ', this.menuIsOpen);
  }

  @computed getImageUrlFromIPFSHash(ipfsHash: string): string {
    return this.baseURI + ipfsHash;
  }

}