import app from '../../../app';
import React, { useEffect, useState } from 'react';
import crownMiddleLogo from '../../../assets/img/logo/crown-middle-logo.png';
import menuScroll from '../../../assets/img/logo/logo-menu-scroll.png';
import MobileMenu from '../../../assets/img/menu-mobile.png';
import { observer } from 'mobx-react';

const Header = observer((): JSX.Element => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 200) {
        setClassName('');
      } else {
        setClassName('sticky-menu');
      }
    };
  }, []);


  const getSideBarOpenClass = (): string => {
    return app.vars.menuIsOpen ? 'visible' : ' ';
  };

  // const handleConnectWalletClicked = (): void => {
  //   //  console.log('CWC');
  //   //  console.log(' s', app.web3.accounts[0])
  // };

  return (
    <header className="third-header-bg home-five-header">
      <div className="bg"></div>
      <div className="container custom-container">
        <div className="header-top-area t-header-top-area d-none d-lg-block">
          <div className="row">
            <div className="col-sm-6">
              <div className="header-top-social">
                <ul>
                  <li>Follow us</li>
                  <li><a href="https://twitter.com/kings_strategy" target='blank'><i className="fab fa-twitter"></i></a></li>
                  <li><a href="https://discord.com/invite/dvY5BTEWJt" target='blank'><i className="fab fa-discord"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="header-top-login">
                <ul>
                  <li><a href="/strategy" /* onClick={handleConnectWalletClicked} */><i className="far fa-edit"></i>Subscribe to KoS</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sticky-header" className={className}>
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="main-menu menu-style-two">
                <nav>
                  <div className="logo d-block d-lg-none">
                    <a href="/">
                      <img src={menuScroll} alt="Logo" /></a>
                  </div>

                  <div className="navbar-wrap d-none d-lg-flex">
                    <ul className="left">
                      <li className="show"><a href="#">Home</a>
                      </li>
                      <li><a href="#kingdom-awaits">Overview</a></li>
                      <li><a href="/wp.pdf" download>White Paper</a></li>
                      <li><a href="/strategy" target="_blank" rel="noreferrer">Whitelist</a></li>
                      {/* <li><a href="/assets">Assets</a></li> */}
                    </ul>
                    <div className="logo">
                      <a href="/"><img src={crownMiddleLogo} alt="Logo" /></a>
                    </div>
                    <div className="logo-scroll">
                      <a href="/"><img src={menuScroll} alt="Logo" /></a>
                    </div>
                    <ul className="right">
                      {/* <li><a href="/bored-apes-generator" target="_blank" rel="noreferrer">BASGB Generator</a></li> */}
                      <li><a href="https://discord.gg/5vtbKkk2sG" target="_blank" rel="noreferrer">Discord Community</a></li>
                      <li><a href="mailto:admin@kingsofstrategy.com">contact</a></li>
                    </ul>
                  </div>

                  <span className="ToggleMenu" onClick={() => app.vars.toggleMenu()}>Menu  <img src={MobileMenu} width="20px" /></span>

                </nav>
              </div>


              <div className={`mobile-menu-wrap d-block d-lg-none ${getSideBarOpenClass()}`}>

                <a className="navbar-brand m-0" href="/strategy">
                  <img src={crownMiddleLogo} className="navbar-brand-img" alt="..." />
                </a>

                <ul className="menuMobile">
                  <li className="show"><a href="#">Home</a></li>
                  <li><a href="#kingdom-awaits">Overview</a></li>
                  <li><a href="/wp.pdf" download>White Paper</a></li>
                  <li><a href="/strategy" target="_blank" rel="noreferrer">Whitelist</a></li>
                  <li><a href="https://discord.gg/5vtbKkk2sG" target="_blank" rel="noreferrer">Discord Community</a></li>
                  <li><a href="mailto:admin@kingsofstrategy.com">contact</a></li>
                </ul>

              </div>
              <div className="mobile-menu"></div>
            </div>

            <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." />
                    <button><i className="fa fa-search"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom-bg"></div>
    </header>


  );
});
export default Header;