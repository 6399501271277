/* eslint-disable multiline-ternary */
import { action, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
export interface IModalStore {
  modalToShow: string;
  buttonText: string;
  closeButtonText: string;
}
export class ModalStore implements IModalStore {
  id = uuidv4();
  isOpen = false;
  modalToShow = '';
  modalClass = 'smallModal';
  buttonText = '';
  closeButtonText = '';

  constructor() {
    makeAutoObservable(this);
  }

  @action closeModal(): void {
    this.isOpen = false;
  }

  @action openModal(modalToOpen: string): void {
    this.modalToShow = modalToOpen;
    this.isOpen = true;
  }





}
