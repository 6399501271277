import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import 'animate.css/animate.css';
import { firebaseConfig } from './configs/fbConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'; // make sure you add this for firestore
import './assets/css/bootstrap.min.css';// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/animate.min.css';
// import '../node_modules/react-modal-video/scss/modal-video.scss';
import './assets/css/magnific-popup.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/odometer.css';
import './assets/css/aos.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/meanmenu.css';
import './assets/css/slick.css';
import './assets/css/default.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
//import './assets/js/main.js'


firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);