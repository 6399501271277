/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import app from '../../app';
import apeAlpha from '../../assets/img/images/blank-ape.jpg';
import { ethers } from 'ethers';
import { balanceOf1155 } from '../../utils/nft-utils/balance-of-functions/balanceOf1155';

export interface IBAGeneratorStore {
  wlNFTContractAddress: string;
  wlNFTTokenId: number;
  generatorNFTContractAddress: string;
  generatorNFTTokenId: number;
  wlTokenBalance: number;
  generatorTokenBalance: number;
  mintConfirmed: boolean;
}

export class BAGeneratorStore implements IBAGeneratorStore {
  id = uuidv4();
  wlNFTContractAddress = "0xC84490846AFEf9Ca5e70535cE993Fd8e4217F425";
  wlNFTTokenId = 4;
  generatorNFTContractAddress = "0xC84490846AFEf9Ca5e70535cE993Fd8e4217F425";
  nftpShufflePacksAddress = "0x9a300951b7B38b0C353560aefE1000cdfeB94AB3";
  shuffleContractAddress = "0xf9143b4574b124210f479f6E6A48a43ED3020520";
  generatorNFTTokenId = 35;
  planetOfBATokenId = 14;
  planetBaTokenBalance = 0;
  wlTokenBalance = 0;
  generatorTokenBalance = 0;
  shufflePackV1Balance = 0;
  shufflePackV2Balance = 0;
  lastShuffleTimestamp = 0;
  isEligibleToMint = false;
  isEligibleToShuffle = false;
  hasPendingShuffle = false;
  hasPendingMint = false;
  generalProject = false;
  ffProject = false;
  hugoProject = false;
  celebrate = false;
  jewelsProject = false;
  ladyCryptoProject = false;
  riddlerProject = false;
  genBurnApproved = false;
  mintableShuffleData: Record<string, any> = {};
  attributes: Record<string, unknown>[] = [];
  shuffleId = 0;
  projectName = '';
  pendingShuffleTs = 0;
  lastUserMintedTokenId = 0;
  totalMinted = 0;
  rowData: Record<string, unknown>[] = [];
  myJungle: string[] = [];

  wlBurnContract = "0x76219974563Ed65212c6c51A5281038dd4a53fe0";

  imageSource = apeAlpha;
  mintConfirmed = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action setPendingShuffleTs(pendingShuffleTs: number): void {
    this.pendingShuffleTs = pendingShuffleTs;
  }
  @action setImageSource(newSource: string): void {
    this.imageSource = newSource;
  }
  @action setMyJungle(newList: string[]): void {
    this.myJungle = newList;
  }
  @action setTotalMinted(total: number): void {
    this.totalMinted = total;
  }
  @action async shuffleNFT(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const options = this.getOptionsValue();
      console.log(options);
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ["function requestShuffle(uint256 _options) external returns (uint256)"];
      const shuffleContract = new ethers.Contract(this.shuffleContractAddress, abi, prov.getSigner());
      await shuffleContract.requestShuffle(options);
      this.setHasPendingShuffle(true);
      this.setImageSource(apeAlpha);
      this.setPendingShuffleTs(Date.now());
      // const tx = await result.wait();
      // this.setHasPendingShuffle(false);
      // console.log(tx);
    }
    //this.isLoading = true;
    // const src = await getRandomWLImage();
    // this.setImageSource(src);
    //this.imageSource = await getRandomHorseImage();
    //  this.isLoading = false;
  }
  @action setGeneralProject(value: boolean): void {
    this.generalProject = value;
  }
  @action setCelebrate(value: boolean): void {
    this.celebrate = value;
  }
  @action setLastUserMintedTokenId(value: number): void {
    this.lastUserMintedTokenId = value;
  }

  @action setFFProject(value: boolean): void {
    this.ffProject = value;
  }

  @action setHugoProject(value: boolean): void {
    this.hugoProject = value;
  }

  @action setJewelsProject(value: boolean): void {
    this.jewelsProject = value;
  }

  @action setLadyCryptoProject(value: boolean): void {
    this.ladyCryptoProject = value;
  }

  @action setRiddlerProject(value: boolean): void {
    this.riddlerProject = value;
  }

  @action setLastShuffleTimeStamp(value: number): void {
    this.lastShuffleTimestamp = value;
  }
  @action async setIsEligibleToMint(value: boolean): Promise<void> {
    this.isEligibleToMint = value;
  }
  @action setIsEligibleToShuffle(value: boolean): void {
    this.isEligibleToShuffle = value;
  }
  @action setHasPendingShuffle(value: boolean): void {
    this.hasPendingShuffle = value;
  }
  @action setMintableShuffleData(value: Record<string, any>): void {
    this.mintableShuffleData = value;
  }
  @action setAttributes(value: Record<string, unknown>[]): void {
    this.attributes = value;
  }
  @action setShuffleId(value: number): void {
    this.shuffleId = value;
  }
  @action setHasPendingMint(value: boolean): void {
    this.hasPendingMint = value;
  }
  @action setGenBurnApproved(value: boolean): void {
    this.genBurnApproved = value;
  }
  @action setProjectName(value: string): void {
    this.projectName = value;
  }
  @action async setEligibility(): Promise<unknown> {
    const result = await axios.get(`https://us-central1-nft-apps.cloudfunctions.net/generatorAPI/addressStatus/${app.user.address}`);
    const data = result.data;
    this.setHasPendingMint(data.pendingMint);
    this.setHasPendingShuffle(data.pendingShuffle);
    const canShuffle = await this.checkIfUserIsEligibleToShuffle();
    this.setIsEligibleToShuffle(canShuffle);
    this.setIsEligibleToMint(data.mintableShuffle);
    this.setTotalMinted(data.qtyMinted);
    if (data.mintableShuffle) {
      await app.baGenerator.getMintableShuffleData();
    } else {
      this.setImageSource(apeAlpha);
    }
    if (this.hasPendingShuffle) {
      await this.getPendingShuffleData();
      // if (Date.now() > this.pendingShuffleTs + 10000) {
      //   //Refresh Pending Shuffle     
      //   await axios.get(`https://us-central1-nft-apps.cloudfunctions.net/generatorAPI/refreshShuffleRequest/${app.user.address.toLowerCase()}`);
      // }
    }
    await this.checkGenBurnApproved();
    return;
  }
  @action async getMintableShuffleData(): Promise<unknown> {
    const result = await axios.get(`https://us-central1-nft-apps.cloudfunctions.net/generatorAPI/mintableShuffle/${app.user.address.toLowerCase()}`);
    const data = result.data;
    this.setMintableShuffleData(data.mintableShuffleData);
    this.setImageSource(data.url);
    this.setAttributes(data.rarity);
    this.setShuffleId(data.shuffleId);
    this.setProjectName(data.name);
    return;
  }
  @action async getPendingShuffleData(): Promise<unknown> {
    const result = await axios.get(`https://us-central1-nft-apps.cloudfunctions.net/generatorAPI/pendingShuffle/${app.user.address.toLowerCase()}`);
    const data = result.data;
    this.setPendingShuffleTs(data.timestamp);
    return;
  }
  @action setRowData(rowData: Record<string, unknown>[]): void {
    this.rowData = rowData;
  }
  async getMyJungleURLs(): Promise<unknown> {
    const result = await axios.get(`https://us-central1-nft-apps.cloudfunctions.net/generatorAPI/myJungle/${app.user.address.toLowerCase()}`);
    const jungle = result.data.jungle;
    console.log("data", jungle);
    if (jungle.length < 10) {
      const diff = 10 - jungle.length;
      for (let i = 0; i < diff; i++) {
        jungle.push(apeAlpha);
      }
    }
    this.setMyJungle(jungle);
    return;
  }
  @computed getShuffledImageURL(): string {
    return this.mintableShuffleData.url;
  }
  checkIfUserIsEligibleToShuffle(): boolean {
    const hasCredits = app.wallet.shuffleBalance >= 1;
    const hasGeneratorToken = app.baGenerator.generatorTokenBalance > 0;
    console.log(hasCredits, hasGeneratorToken);
    return hasCredits && hasGeneratorToken;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  compare(a, b): number {
    if (a.option < b.option) {
      return -1;
    }
    if (a.option > b.option) {
      return 1;
    }
    return 0;
  }


  async loadTraitData(): Promise<unknown> {
    const response = await axios.get("https://us-central1-nft-apps.cloudfunctions.net/generatorAPI/traits");
    console.log("Response: ", response.data.listOfTraitItems);
    const tempList = response.data.listOfTraitItems;
    tempList.sort(this.compare);
    this.setRowData(tempList);
    console.log("Loading Data");

    return;
  }

  async checkGenBurnApproved(): Promise<void> {
    const abi = ['function isApprovedForAll(address account, address operator) external view returns (bool)'];
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const tokenContract = new ethers.Contract(app.baGenerator.generatorNFTContractAddress, abi, provider);
      const result = await tokenContract.isApprovedForAll(app.user.address, app.baGenerator.shuffleContractAddress);
      console.log("Gen Burn Result ", result);
      this.setGenBurnApproved(result);
    } catch (error) {
      console.log('error ', error);
    }
  }


  async reloadData(): Promise<void> {
    app.wallet.setShuffleBalance();
    app.wallet.setCandBalance();
    app.wallet.setSGBBalance();
    app.wallet.setNFTPBalance();
    await this.setWhiteListNFTBalance();
    await this.setGeneratorNFTBalance();
    await this.setShufflePackV1Balance();
    await this.setShufflePackV2Balance();
    await this.setPlanetBABalance();
    await this.setEligibility();
    await this.getMyJungleURLs();
  }

  async checkIfUserIsEligibleToMint(): Promise<boolean> {
    // Check if They Own Gen Token
    const hasGeneratorToken = app.baGenerator.generatorTokenBalance > 0;
    const lastBlockTs = await this.getLastBlockTimestamp();
    const oldestAccepted = lastBlockTs - 4000;
    // Check if they have an eligible shuffle within the last hour
    let valueToReturn = false;
    if (app.user.address) {
      const query = app.fb.db?.collection('baGenerator/shuffles/results').where('user', '==', app.user.address.toLowerCase());
      const query2 = query?.where('ts', '>=', oldestAccepted);
      const results = await query2?.get();
      if (results && results.docs.length > 0) {
        valueToReturn = true;
      }
    }
    return valueToReturn && hasGeneratorToken;
  }
  async getLastBlockTimestamp(): Promise<number> {
    const provider = app.web3._getProvider();
    const block = await provider.getBlock('latest');
    return block.timestamp;
  }

  async checkIfUserHasPendingMint(): Promise<boolean> {
    let valueToReturn = false;
    if (app.user.address) {
      const query = app.fb.db?.collection('baGenerator/mint/requests').where('user', '==', app.user.address.toLowerCase());
      const results = await query?.get();
      if (results && results.docs.length > 0) {
        valueToReturn = true;
      }
    }
    return valueToReturn;
  }
  @computed getOptionsValue(): number {
    const general = this.generalProject ? '1' : '0';
    const ff = this.ffProject ? '1' : '0';
    const hugo = this.hugoProject ? '1' : '0';
    const jewels = this.jewelsProject ? '1' : '0';
    const ladyCrypto = this.ladyCryptoProject ? '1' : '0';
    const riddler = this.riddlerProject ? '1' : '0';
    const value = `1${general}${ff}${hugo}${jewels}${ladyCrypto}${riddler}`;
    return parseInt(value);
  }
  @computed ownsWhiteListNFT(): boolean {
    return this.wlTokenBalance > 0;
  }
  @computed ownsGeneratorNFT(): boolean {
    return this.generatorTokenBalance > 0;
  }

  @action async setWhiteListNFTBalance(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const bal = await balanceOf1155(this.wlNFTContractAddress, app.user.address, this.wlNFTTokenId, app.web3.ethersProvider);
      this.wlTokenBalance = bal ? bal.toNumber() : 0;
      // console.log(this.wlTokenBalance);
    }
  }

  @action async setPlanetBABalance(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const bal = await balanceOf1155(this.wlNFTContractAddress, app.user.address, this.planetOfBATokenId, app.web3.ethersProvider);
      this.planetBaTokenBalance = bal ? bal.toNumber() : 0;
      // console.log(this.wlTokenBalance);
    }
  }
  @action async setGeneratorNFTBalance(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const bal = await balanceOf1155(this.generatorNFTContractAddress, app.user.address, this.generatorNFTTokenId, app.web3.ethersProvider);
      this.generatorTokenBalance = bal ? bal.toNumber() : 0;
      // console.log(this.generatorTokenBalance);
    }
  }
  @action async setShufflePackV1Balance(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const balV1 = await balanceOf1155(this.generatorNFTContractAddress, app.user.address, 114, app.web3.ethersProvider);
      this.shufflePackV1Balance = balV1 ? balV1.toNumber() : 0;
    }
  }
  @action async setShufflePackV2Balance(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const balV2 = await balanceOf1155(this.nftpShufflePacksAddress, app.user.address, 1, app.web3.ethersProvider);
      this.shufflePackV2Balance = balV2 ? balV2.toNumber() : 0;
    }
  }
}