import React, { useEffect, useState } from 'react';
import Odometer from 'react-odometerjs';
import VisibilitySensor from 'react-visibility-sensor';
import horseRacingIcon from '../../../assets/img/icon/horse-racing-icon.png';
import golfIcon from '../../../assets/img/icon/golf-icon.png';
import franchiseIcon from '../../../assets/img/icon/franchise-icon.png';
import subscribersIcon from '../../../assets/img/icon/subscribers-icon.png';
const EconomySection = (): JSX.Element => {
  const [tracks, setNumberOfTracks] = useState(0);
  const [courses, setNumberOfGolfCourses] = useState(0);
  const [franchises, setNumberOfFranchises] = useState(0);
  const [subscribers, setNumberOfSubscribers] = useState(0);

  function onChange(isVisible) {
    if (isVisible) {
      setNumberOfTracks(12);
      setNumberOfGolfCourses(18);
      setNumberOfFranchises(32);
      setNumberOfSubscribers(10);
    } else {
      setNumberOfTracks(0);
      setNumberOfGolfCourses(0);
      setNumberOfFranchises(0);
      setNumberOfSubscribers(0);
    }
  }

  useEffect(() => {
    //  window.onscroll = () => {
    if (window.pageYOffset < 1300) {
      setNumberOfTracks(0);
      setNumberOfGolfCourses(0);
      setNumberOfFranchises(0);
      setNumberOfSubscribers(0);
    } else {
      setNumberOfTracks(12);
      setNumberOfGolfCourses(18);
      setNumberOfFranchises(32);
      setNumberOfSubscribers(10);
    }
    //  }
  }, []);

  return (

    <section className="features-area virtual-sport bright pt-120 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-two text-center mb-60">
              <span>How are we different?</span>
              <h2>First <span>Virtual Sports Economy</span></h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-6">
            <div className="features-item mb-30">
              <div className="features-head mb-35">
                <div className="product-tag"><a href="#">Horse Racing</a></div>
                <h4>Sires of Strategy</h4>
                <p>The virtual horse racing game where you can own a race horse, or you can own the race track!
                  And you can make income from both!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="features-item mb-30">
              <div className="features-head mb-35">
                <div className="product-tag"><a href="#">Golf</a></div>
                <h4>Aces of Strategy</h4>
                <p>The virtual golf game where you can own your golfer, or you can own the golf course!
                  And you can make income from both!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="features-item mb-30">
              <div className="features-head mb-35">
                <div className="product-tag"><a href="#">Soccer</a></div>
                <h4>Goals of Strategy</h4>
                <p>The virtual football game where you can own a player, or you can own a franchise!
                  And yes, you can make income from both!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="fact-area pt-90">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="fact-item">
                <div className="fact-icon">
                  <img src={horseRacingIcon} alt="" />
                </div>
                <VisibilitySensor onChange={onChange}>
                  <div className="fact-content">
                    <h2><span className="odometer"><Odometer value={tracks} format="(.ddd),dd" /></span></h2>
                    <span>Horse Racing Tracks</span>
                  </div>
                </VisibilitySensor>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="fact-item">
                <div className="fact-icon">
                  <img src={golfIcon} alt="" />
                </div>
                <div className="fact-content">
                  <h2><span className="odometer"><Odometer value={courses} format="(.ddd),dd" /></span></h2>
                  <span>Golf Courses</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="fact-item">
                <div className="fact-icon">
                  <img src={franchiseIcon} alt="" />
                </div>
                <div className="fact-content">
                  <h2><span className="odometer"><Odometer value={franchises} format="(.ddd),dd" /></span></h2>
                  <span>Franchises</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="fact-item">
                <div className="fact-icon">
                  <img src={subscribersIcon} alt="" />
                </div>
                <div className="fact-content">
                  <h2><span className="odometer"><Odometer value={subscribers} format="(.ddd),dd" /></span>K</h2>
                  <span>Subscribers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EconomySection;