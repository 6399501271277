// **************************** ERC 721 Event ABIs ************************************************************************
export const TRANSFER_EVENT_ABI_1155_SINGLE = ["event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)"];
export const TRANSFER_EVENT_ABI_1155_BATCH = ["event TransferBatch(address indexed operator, address indexed from,address indexed to,uint256[] ids,uint256[] values)"];
export const APPROVAL_FOR_ALL_EVENT_1155 = ["event ApprovalForAll(address indexed account, address indexed operator, bool approved)"];

// **************************** ERC 721 Event ABIs ************************************************************************
export const ALL_1155_EVENTS = [
  "event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)",
  "event TransferBatch(address indexed operator, address indexed from,address indexed to,uint256[] ids,uint256[] values)",
  "event ApprovalForAll(address indexed account, address indexed operator, bool approved)"
];


// **************************** ERC 721 State Modifying ABIs ***************************************************************
export const TRANSFER_FUNCTION_ABI_1155 = ["function safeTransferFrom(address from, address to,uint256 id,uint256 amount,bytes memory data) public"];
export const BATCH_TRANSFER_FUNCTION_ABI_1155 = ["function safeBatchTransferFrom(address from, address to, uint256[] memory ids, uint256[] memory amounts, bytes memory data) public"];
export const SET_APPROVAL_FOR_ALL_FUNCTION_ABI_1155 = ["function setApprovalForAll(address operator, bool approved) public"];

// **************************** ERC 721 View Function ABIs *****************************************************************
export const BALANCE_OF_ABI_1155 = ["function balanceOf(address account, uint256 id) external view returns (uint256)"];
export const BALANCE_OF_BATCH_ABI_1155 = ["function balanceOfBatch(address[] calldata accounts, uint256[] calldata ids) external"];
export const URI_FUNCTION_ABI_1155 = ["function uri(uint256 tokenId) public view returns (string memory)"];
export const GET_IS_APPROVED_ALL_FUNCTION_ABI_1155 = ["function isApprovedForAll(address account, address operator) external view returns (bool)"];
export const TOTAL_SUPPLY_ABI_1155 = ["function totalSupply(uint256 id) public view returns(uint256)"];


