
export class User {
  address: string
  userName: string
  email: string
  avatar: string
  twitter: string
  url: string
  bio: string
  listView: boolean
  rightHanded: boolean

  constructor(address: string, userName: string, email: string, avatar: string, twitter: string, url: string, bio: string) {
    this.address = address;
    this.userName = userName;
    this.email = email;
    this.avatar = avatar;
    this.twitter = twitter;
    this.url = url;
    this.bio = bio;
    this.listView = false;
    this.rightHanded = true;
  }
}

export class UserFactory {

  static fromVoid(): User {
    return new User('', '', '', '', '', '', '');
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSONObject(jsonData: Record<string, any>): User {
    const user: User = new User(jsonData.address, jsonData.userName, jsonData.email, jsonData.avatar, jsonData.twitter, jsonData.url, jsonData.bio);
    // itemToReturn.uid = jsonData.objectId;
    return user;
  }
}

