// ** Router Import
import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import app from ".";
import { observer } from 'mobx-react';
import { routes } from "../utils";
import { ModalShared } from "../components";
//import "../assets/scss/index.scss";

const App = observer((): JSX.Element => {
  const [web3Initialized, setWeb3Initialized] = useState(false);

  //Initialize Web3 Window Listeners
  const setWindowListeners = () => {
    if (!web3Initialized) {
      if (window.ethereum !== undefined) {
        app.web3.initializeWeb3();
        window.ethereum.autoRefreshOnNetworkChange = false;
        window.ethereum.on('chainChanged', app.web3.setChainId);
        window.ethereum.on('accountsChanged', app.web3.setAccounts);
        setWeb3Initialized(true);
      }
    }
  };

  //Initialize Web3 Window Listeners
  window.addEventListener('DOMContentLoaded', setWindowListeners);

  React.useEffect(() => {
    // Specify how to clean up after this effect:
    //Initialize Default Variables and Firebase
    // app.vars.initializeVars();
    app.fb.initializeFB();

  }, []);

  return (
    <Router>
      {app.modal.isOpen && (
        <ModalShared />
      )}
      <Switch>
        <Suspense
          fallback={
            <div className="main-body">
              <div>Loading... </div>
            </div>
          }
        >
          {routes.map((route, i) => {
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
        </Suspense>
      </Switch>
    </Router>
  );
});

export default App;
