import { ethers } from "ethers";
import { SET_APPROVAL_FOR_ALL_FUNCTION_ABI_1155 } from "../abis/abi-1155/ABI_1155";
import { SET_APPROVAL_FOR_ALL_FUNCTION_ABI_721 } from "../abis/abi-721/ABI_721";



export const approveAll721 = async (contractAddress: string, operator: string, approvalStatus: boolean, provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider): Promise<unknown> => {
  try {
    const tokenContract = new ethers.Contract(contractAddress, SET_APPROVAL_FOR_ALL_FUNCTION_ABI_721, provider.getSigner());
    return await tokenContract.setApprovalForAll(operator, approvalStatus);
  } catch (error) {
    console.log('error ', error);
  }
};

export const approveAll1155 = async (contractAddress: string, operator: string, approvalStatus: boolean, provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider): Promise<unknown> => {
  try {
    const tokenContract = new ethers.Contract(contractAddress, SET_APPROVAL_FOR_ALL_FUNCTION_ABI_1155, provider.getSigner());
    return await tokenContract.setApprovalForAll(operator, approvalStatus);
  } catch (error) {
    console.log('error ', error);
  }
};
