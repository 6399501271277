
import { BigNumber, ethers } from 'ethers';
import { action, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import app from '../../app';
//import axios from 'axios';

export interface IWalletStore {
  sgbBalance: number;
  nftpBalance: number;
  candBalance: number;
  shuffleBalance: number;
  nftpAddress: string;
  candAddress: string;
}

export class WalletStore implements IWalletStore {
  id = uuidv4();

  nftpAddress = '0x3B46090e608cBC963356f30857F4DAcC09f5DDC4';
  candAddress = '0x70Ad7172EF0b131A1428D0c1F66457EB041f2176';
  shuffleCreditsAddress = "0x31Bddca09e3d11d96850199510e77390Ba7e2649";
  sgbBalance = 0;
  candBalance = 0;
  nftpBalance = 0;
  shuffleBalance = 0;

  constructor() {
    makeAutoObservable(this);
  }
  @action async setSGBBalance(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const bal = await app.web3.ethersProvider.getBalance(app.web3.accounts[0]);
      const formattedBalance = ethers.utils.formatEther(bal).toString();
      const tempBal = parseFloat(formattedBalance).toFixed(0);
      this.sgbBalance = parseFloat(tempBal);
    }
  }
  @action async setCandBalance(): Promise<void> {
    this.candBalance = await this.getBalanceOfERC20(this.candAddress);
  }
  @action async setShuffleBalance(): Promise<void> {
    this.shuffleBalance = await this.getBalanceOfERC20(this.shuffleCreditsAddress);
  }
  @action async setNFTPBalance(): Promise<void> {
    this.nftpBalance = await this.getBalanceOfERC20(this.nftpAddress);
  }

  async getBalanceOfERC20(erc20Address: string): Promise<number> {
    if (app.user.isConnected) {
      if (window.ethereum && app.user.address) {
        try {
          const abi = ['function balanceOf(address account) external view returns (uint256)'];
          const contract = new ethers.Contract(erc20Address, abi, app.web3.ethersProvider);
          const balance: BigNumber = await contract.balanceOf(app.user.address);
          const valToReturn = parseFloat(app.web3.convertWEIToETH(balance.toString()));
          const floatValue = parseFloat(valToReturn.toFixed(0));
          return Math.floor(floatValue);
        } catch (error) {
          console.log('error ', error);
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
}