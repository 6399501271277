import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, FormGroup, Input } from 'reactstrap';
import app from '../../../app';
import { ethers } from 'ethers';
import { getIsApprovedAll1155 } from '../../../utils/nft-utils/get-approved-functions/getApprovedAll';
import { approveAll1155 } from '../../../utils/nft-utils/approval-functions/approveAll';


const BurnSP1Modal = observer((): JSX.Element => {
  const [qty, setQty] = useState(1);
  const [totalCredits, setTotalCredits] = useState(5);
  const [isApproved, setIsApproved] = useState(false);


  const handleBurnClicked = async (): Promise<void> => {
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ['function mintShuffleCredits(uint256 _qtyNFTs, uint256 _utilityNftId, uint256 _boostId) external'];
      const shuffleCreditMintContract = new ethers.Contract(app.vars.shuffleCreditMinterAddress, abi, prov.getSigner());
      await shuffleCreditMintContract.mintShuffleCredits(qty, 2, 0);
      app.modal.closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const checkIfApproved = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const approved: boolean = await getIsApprovedAll1155(app.baGenerator.generatorNFTContractAddress, app.user.address, app.vars.shuffleCreditMinterAddress, prov);
      console.log(approved);
      setIsApproved(approved);
    }
  };

  const handleApproveAllClicked = async () => {
    if (app.user.address && window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const result = await approveAll1155(app.baGenerator.generatorNFTContractAddress, app.vars.shuffleCreditMinterAddress, true, prov);
      console.log(result);
      app.modal.closeModal();
    }
  };
  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQty(parseInt(e.target.value));
    setTotalCredits(parseInt(e.target.value) * 5);
  };

  const isValid = (): boolean => {
    const hasSP = app.baGenerator.shufflePackV1Balance >= qty;
    return hasSP && isApproved;
  };

  useEffect(() => {
    checkIfApproved();
  }, [app.user.address]);


  return (
    <Modal className="largeModal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">

        <h4 className="title title-up">Burn your <strong>Shuffle Pack 1<sup>st</sup> Edition</strong> NFTs</h4>
      </div>
      <div className="modal-body">
        <p className="text-center">
          You are about to burn some <strong>Shuffle Pack NFTs</strong> to get 5 Shuffle points.
        </p>

        <div className="inputlines">


          <FormGroup>
            <label>Quantity to burn</label>

            <Input
              min="1"
              placeholder="Quantity"
              id="qte"
              value={qty}
              type="number"
              onChange={(e) => handleQtyChange(e)}
            />
            <div className="subinput">
              Currently you own <span>{app.baGenerator.shufflePackV1Balance}</span> Shuffle Pack NFT.
            </div>

          </FormGroup>
        </div>

        <div className="biddetails">
          <span className="left">You will get</span>
          <span className="right">{totalCredits} Shuffle Points</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Cancel
        </Button>
        {isValid() ? <Button
          color="default"
          className="btn-round"
          type="button"
          onClick={() => handleBurnClicked()}>
          Burn my Shuffle Pack(s)
        </Button> : <></>}
        {!isApproved && app.baGenerator.shufflePackV1Balance > 0 ? <Button
          color="default"
          className="btn-round"
          type="button"
          onClick={() => handleApproveAllClicked()}>
          Approve
        </Button> : <></>}
      </div>
    </Modal>
  );
});
export default BurnSP1Modal;